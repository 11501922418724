import React, { Component } from 'react'
import { StaticQuery, graphql } from "gatsby"
import twitterIcon from '../assets/images/twitter-icon.svg'
import fbIcon from '../assets/images/facebook-icon.svg'
import instaIcon from '../assets/images/insta-icon.svg'
import { getDataForLanguage } from '../utils/getDataForLanguage'
import {get,first} from 'lodash'

export class SocialLinks extends Component {
    render() {
        return (
            <StaticQuery
                query={graphql`
                    query SocialLinksQuery {
                        allContentfulSiteInformations {
                            edges {
                                node {
                                    socialLinks
                                    node_locale
                                    contentful_id
                                }
                            }
                        }
                    }`
                }
                render={(data) => {
                    const allPageData = get(data, "allContentfulSiteInformations.edges", '');
                    let pageData = first(getDataForLanguage(allPageData.map(item => item.node), process.env.COUNTRY));
                    return(
                        <div className="social-links">
                            <ul>
                                {get(pageData, 'socialLinks', false) && pageData.socialLinks.map((socialLink) => {
                                    return(
                                        <li key={socialLink}>
                                            <a href={socialLink} target="_blank" rel="noopener noreferrer">
                                                {
                                                    (() => {
                                                        if(socialLink.includes('facebook')) {
                                                            return(<img src={fbIcon} alt="Facebook opens in a new tab" />)
                                                        } else if (socialLink.includes('twitter')) {
                                                            return(<img src={twitterIcon} alt="Twitter opens in a new tab" />)
                                                        } else if (socialLink.includes('instagram')) {
                                                            return(<img src={instaIcon} alt="Instagram opens in a new tab" />)
                                                        } else {
                                                            return(<img src={fbIcon} alt="Facebook opens in a new tab" />)
                                                        }
                                                    })()
                                                }
                                            </a>
                                        </li>
                                    )
                            
                                })}
                            </ul>
                        </div>
                    )
                }}
            />
        )
    }
}

export default SocialLinks