import React from 'react'
import { StaticQuery, graphql, Link } from "gatsby"
//import SubNavImage from "../../assets/images/subnav.png"
import BackImage from "../../assets/images/arrow--back.svg"
import SubNavs from "./subNavs"
import { get } from 'lodash'
import { getDataForLanguage} from '../../utils/getDataForLanguage'

const SubNav = () => {

    return (
        <StaticQuery
            query={graphql`
                query SubNavigation { 
                    allContentfulProduct(sort: {fields: order}) {
                        nodes {
                            availability
                            category
                            title
                            slug
                            order
                            node_locale
                            contentful_id
                        }
                    }
                }`
            }
            render={(data) => {
                const allData = get(data, 'allContentfulProduct.nodes', []);
                const subNavs = getDataForLanguage(allData, process.env.COUNTRY);

                return (
                    <div className="product-dropdown" id="productDropdown">
                        <div className="product-dropdown__back">
                            <img src={BackImage} alt="Back to - image" onClick={() => {
                                document.querySelector('.product-mobile-title').classList.toggle('product-mobile-title--active')
                                document.querySelector('.product-dropdown').classList.toggle('product-dropdown--mobile-active')
                                document.querySelector('.language-picker').classList.toggle('z-index')
                                document.querySelector('.search-wrap').classList.toggle("z-index")
                            }} />
                        </div>
                        <div className="product-dropdown__helper">
                            <div className="container-lg">
                                <div className="row product-dropdown__boxes">

                                    {process.env.COUNTRY === "en-US" && 
                                        <>
                                            <SubNavs value={subNavs.filter(h => h.category == "Hand").filter(c => c.availability == "US site" || c.availability == "US and Canadian site" )} title="Hand" tabIndex={1} />
                                            <SubNavs value={subNavs.filter(h => h.category == "Foot").filter(c => c.availability == "US site" || c.availability == "US and Canadian site" )} title="Foot" tabIndex={2} />
                                            <SubNavs value={subNavs.filter(h => h.category == "Lip").filter(c => c.availability == "US site" || c.availability == "US and Canadian site" )} title="Lip" tabIndex={3} />
                                            <SubNavs value={subNavs.filter(h => h.category == "Body").filter(c => c.availability == "US site" || c.availability == "US and Canadian site" )} title="Body" tabIndex={4} />
                                        </>
                                    }

                                    {process.env.COUNTRY === "fr-CA" && 
                                        <>
                                            <SubNavs value={subNavs.filter(h => h.category == "Hand").filter(c => c.availability == "Canadian site" || c.availability == "US and Canadian site" )} title="Hand" tabIndex={1} />
                                            <SubNavs value={subNavs.filter(h => h.category == "Foot").filter(c => c.availability == "Canadian site" || c.availability == "US and Canadian site" )} title="Foot" tabIndex={2} />
                                            <SubNavs value={subNavs.filter(h => h.category == "Lip").filter(c => c.availability == "Canadian site" || c.availability == "US and Canadian site" )} title="Lip" tabIndex={3} />
                                            <SubNavs value={subNavs.filter(h => h.category == "Body").filter(c => c.availability == "Canadian site" || c.availability == "US and Canadian site" )} title="Body" tabIndex={4} />
                                        </>
                                    }

                                    <div className="product-dropdown__box product-dropdown__box--shop-all">
                                        <Link className="product-dropdown__title" to="/products">Shop All</Link>
                                    </div>
                                    {/* <div className="product-dropdown__box product-dropdown__box--image">
                                        <img src={SubNavImage} alt="Image for: Try NEW O&apos;Keeffe&apos;s Lip Repair Night Treatment"/>
                                        <Link to="/products/lip-repair-night">Try NEW O&apos;Keeffe&apos;s Lip Repair Night Treatment</Link>
                                    </div> */}
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }}
        />
    )
}

export default SubNav