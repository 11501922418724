import React, { useState } from 'react'
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby"
import Logo from '../assets/images/site-logo.svg'
import Hamburger from '../assets/images/hamburger.svg'
import SubNav from './helpers/productsSubnav'
import Search from './search'
import {get,first} from 'lodash'
import { getDataForLanguage } from '../utils/getDataForLanguage'
import MenuItem from './helpers/menuItem';

const searchIndices = [
    { name: `Search`, title: `Pages`, hitComp: `PageHit` },
]

const Header = (props) => {

    const openModal = props.openModal;

    
    const [ active, setActive ] = useState(false);
    const handleHover = () => {
        // console.log('handing');
        if(active == false) {
            setActive(true);
        } else {
            setActive(false);
        }
    };


    const [ toggle, setToggle ] = useState(false);
    const openDropdown = () => {
        var dropdown = document.getElementById('productDropdown');
        var dropdownContainer = document.querySelectorAll('li.has-dropdown')[0];
        if (toggle == false) {
            setToggle(true);
            dropdown.classList.toggle('active');
        } else if (toggle == true) {
            setToggle(false);
            dropdown.classList.remove('active');
        }
        // console.log(dropdownContainer)
        document.querySelector('body').addEventListener('keydown', (event) => {
            if (event.key == "Esc" || event.key == "Escape") {
                dropdown.classList.remove('active');
            }
            if (!dropdownContainer.contains(event.target)) {
                dropdown.classList.remove('active');
            }
        });
        document.querySelector('body').addEventListener('click', (event) => {
            if (!dropdown.contains(event.target)) {
                dropdown.classList.remove('active');
            }
        });
    };
     
    return (
        <StaticQuery
            query={graphql`
                query HeaderNavigation {
                    allContentfulNavigation(filter: {title: {eq: "Header"}}) {
                        edges {
                            node {
                                title
                                node_locale
                                contentful_id
                                navigationItems {
                                    title
                                    slug
                                    tabNumber
                                    node_locale
                                    contentful_id
                                    subNavigationItem {
                                        title
                                        slug
                                        tabNumber
                                        node_locale
                                        contentful_id
                                        subNavigationItem {
                                            title
                                            slug
                                            tabNumber
                                            node_locale
                                            contentful_id
                                        }
                                    }
                                }
                            }
                        }
                    }
                }`
            }
            render={(data) => {

                const allPageNavData = get(data, "allContentfulNavigation.edges", '');
                let pageNavData = first(getDataForLanguage(allPageNavData.map(item => item.node), process.env.COUNTRY));
                return (
                    <header>
                        <div className="header-inner">
                            
                            <div className="logo">
                                <Link to="/">
                                    <img src={Logo} alt="O'Keeffe's Company" />
                                </Link>
                            </div>
                            <button className="hamburger" onClick={()=> {
                                    document.querySelector('.site-nav').classList.toggle("site-nav--mobile-active")
                                    document.querySelector('.hamburger').classList.toggle("hamburger--mobile-active")
                                    document.querySelector('.logo').classList.toggle("logo--mobile-active")
                                    document.querySelector('.search-wrap').classList.toggle("search-wrap--mobile-active")
                                    document.querySelector('.language-picker').classList.toggle("language-picker--mobile-active")
                                }}>
                                <img src={Hamburger} alt="Menu" />
                            </button>
                            <nav className="site-nav">
                                <ul>
                                    {pageNavData.navigationItems.map((item) => {
                                        if(item.slug === "/products") {
                                            return (
                                                <li key={item.slug} className={"has-dropdown has-dropdown--disabled--" + active}>
                                                    <Link to="/products" onMouseOver={()=> handleHover()}>{item.title}</Link><button onClick={() => openDropdown()} aria-label="Toggle Product Menu"></button>
                                                    <div className="product-mobile-title" onClick={()=> {
                                                        document.querySelector('.product-mobile-title').classList.toggle("product-mobile-title--active")
                                                        document.querySelector('.product-dropdown').classList.toggle("product-dropdown--mobile-active")
                                                        document.querySelector('.language-picker').classList.toggle("z-index")
                                                        document.querySelector('.search-wrap').classList.toggle("z-index")
                                                    }}>{item.title}</div>
                                                    <SubNav />
                                                </li>
                                            )
                                        }
                                        else if (item.slug === "#") {
                                            return (
                                                <li key={item.slug}>
                                                    <a className="overlay" href="#" onClick={()=> openModal()}>
                                                        {item.title}
                                                    </a>
                                                </li>
                                            )
                                        }
                                        return (
                                            <li key={item.slug}><MenuItem subNavigationItem={item} /></li>
                                        )
                                    })}
                                </ul>
                            </nav>
                            <div className="language-picker">
                                {process.env.COUNTRY == 'en-US' && 
                                    <a href="#" className="lang-es lang-select">Español</a>
                                }
                                {process.env.COUNTRY == 'fr-CA' && 
                                    <a href="#" className="lang-fr lang-select">French</a>
                                }
                                <a href="#" className="lang-en lang-select">English</a>
                            </div>
                            <div className="search-wrap">
                                <Search collapse indices={searchIndices} />
                            </div>
                        </div>
                    </header>
                )
            }}
        />
    )
}

Header.propTypes = {
    openModal: PropTypes.any,
}

export default Header