import React from 'react'
import { Link } from "gatsby"
import PropTypes from 'prop-types'
import { get } from 'lodash'

const MenuItem = ({ subNavigationItem }) => {

    if(subNavigationItem.slug.includes('http')) {
        return (<a href={subNavigationItem.slug} target="_blank" rel="noopener noreferrer" >{subNavigationItem.title}<span className="sr-only">opens in a new tab</span></a>)
    }
    return (<Link to={subNavigationItem.slug} state={{ activeTab: get(subNavigationItem, 'tabNumber', null) }} >{subNavigationItem.title}</Link>)
}

MenuItem.propTypes = {
    subNavigationItem: PropTypes.object
}

export default MenuItem