/* eslint-disable */

import React, { useState, useEffect, createRef } from "react"
import {
  InstantSearch,
  Index,
  Hits,
  connectStateResults,
} from "react-instantsearch-dom"
import algoliasearch from "algoliasearch/lite"
import { Root, HitsWrapper } from "./styles"
import Input from "./input"
import {Link} from "gatsby";

const Results = connectStateResults(
  ({ searchState: state, searchResults: res, children }) => {
    if (state.query === '' || state.query === undefined) {
      return(null)
    }
    if(res) {
      if (res.hits) {
        let i = 0;
        let data = res.hits.map(r => {
          const label = r.title;
          return <div className='algolia-search-result'>
            <Link to={r.url}>
              {r.title}
            </Link>
          </div>;
        });
        if (data.length !== 0) {
          return data;
        }
      }
      else {
        return <p>
          No results found for '{state.query}'!
        </p>;
      }
    }
    return <p>
      No results found for '{state.query}'!
    </p>;
  }
);

const Stats = connectStateResults(
  ({ searchResults: res }) =>
    res && res.nbHits > 0 && `${res.nbHits} result${res.nbHits > 1 ? `s` : ``}`
);

const useClickOutside = (ref, handler, events) => {
  if (!events) events = [`mousedown`, `touchstart`]
  const detectClickOutside = event =>
    !ref.current.contains(event.target) && handler()
  useEffect(() => {
    for (const event of events)
      document.addEventListener(event, detectClickOutside)
    return () => {
      for (const event of events)
        document.removeEventListener(event, detectClickOutside)
    }
  })
};

export default function Search({ indices, collapse, hitsAsGrid }) {
  const ref = createRef();
  const [query, setQuery] = useState(``)
  const [focus, setFocus] = useState(false)
  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_SEARCH_KEY
  );

  searchClient.initIndex("Search");

  useClickOutside(ref, () => setFocus(false));

  return (
    <InstantSearch
      searchClient={searchClient}
      indexName={indices[0].name}
      onSearchStateChange={({ query }) => setQuery(query)}
      root={{ Root, props: { ref } }}
    >
      <Input onFocus={() => setFocus(true)} {...{ collapse, focus }} />
      <HitsWrapper show={query.length > 0 && focus} asGrid={hitsAsGrid}>
        {indices.map(({ name, title, hitComp }) => (
          <Index key={name} indexName={name}>
            <Results></Results>
          </Index>
        ))}
      </HitsWrapper>
    </InstantSearch>
  )
}