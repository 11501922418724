import React, { useState } from 'react'
import { StaticQuery, graphql, withPrefix/*, Link*/ } from "gatsby"
import PropTypes from 'prop-types'
import Helmet from "react-helmet"
import Header from '../components/header'
import Footer from '../components/footer'
import Chat from "../components/chat";
import GlobalStyles from "../shared/globalStyles"
import { Modal } from 'react-responsive-modal'
import 'react-responsive-modal/styles.css';
import {get,first} from 'lodash'
import { getDataForLanguage } from '../utils/getDataForLanguage'
//import CookieConsent from "react-cookie-consent"
import '../styles/main.scss'

const Layout = (props) => {

    const siteUrl = process.env.COUNTRY == "en-US" ? "https://okeeffescompany-com.netlify.com" : "https://okeeffescompany-ca.netlify.com";

    const title = get(props, "title", "O'Keeffe's");
    const description = get(props, "description", "O'Keeffe's® provides concentrated, long lasting moisturizers for extremely dry, cracked skin. O'Keeffe's® hard working skincare");
    const image = get(props, "image", "");
    const url = siteUrl + get(props, "pathName", "/");
    const product = props.product ? true : false;

    const [ open, setOpen ] = useState(false);

    const onOpenModal = () => {
        setOpen(true);
        setTimeout(function() {
            document.querySelector('.react-responsive-modal-closeButton').setAttribute('aria-label', 'close');
        }, 1000) 
    };
     
    const onCloseModal = () => {
        setOpen(false);
    };

    const bg = {
        overlay: {
            background: "#198908"
        }
    };

    return (
        <>
            <Helmet>
                <html lang="en" />
                <title>{title}</title>
                <meta name="description" content={description}></meta>
                <meta name="image" content={image} />
                {product && 
                    <meta property="og:type" content="product" />
                }
                <meta property="og:url" content={url} />
                <meta property="og:title" content={title} />
                <meta property="og:description" content={description} />
                <meta property="og:image" content={image} />
                <meta name="twitter:title" content={title} />
                <meta name="twitter:description" content={description} />
                <meta name="twitter:image" content={image} />
                <meta charSet="utf-8" />
                <meta name="google-site-verification" content="N61Z0oXZF9S-c9tFIIDU8Yln2QIN9KcNg-DfNSpOud0" />
                {process.env.COUNTRY == "en-US" && 
                    <script src={withPrefix('translate-en.js')} type="text/javascript" />                
                }
                {process.env.COUNTRY == "fr-CA" && 
                    <script src={withPrefix('translate-ca.js')} type="text/javascript" />                
                }
                <script src={withPrefix('script.js')} type="text/javascript" />
                <script type="text/javascript" src= "https://translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"></script>

                <script src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"  type="text/javascript" charset="UTF-8" data-domain-script="5a959fb8-3204-4069-9cd9-aadffcda5f4b" ></script>
                <script type="text/javascript">
                function OptanonWrapper() { }
                </script>

                
            </Helmet>
            <GlobalStyles />
            <div className="skipnav"><a href="#maincontent">Skip to main content</a></div>
            <Header openModal={onOpenModal} />
            {props.children}
            <Footer openModal={onOpenModal} />
            <StaticQuery
                query={graphql`
                    query MoneyBackModal {
                        allContentfulModal {
                            edges {
                                node {
                                    description
                                    informations
                                    mainText
                                    preTitle
                                    title
                                    node_locale
                                    contentful_id
                                }
                            }
                        }
                        allContentfulSiteInformations {
                            edges {
                                node {
                                    enableChat
                                }
                            }
                        }
                    }`
                }
                render={(data) => {

                    const allPageData = get(data, "allContentfulModal.edges", '');
                    let pageData = first(getDataForLanguage(allPageData.map(item => item.node), process.env.COUNTRY));

                    const enableChatData  = get(data, "allContentfulSiteInformations.edges", '');
                    let enableChat = first(enableChatData);
                    enableChat = enableChat.node.enableChat;
                    
                    return (
                        <>
                            <Modal open={open} onClose={onCloseModal} center styles={bg} role="alert">
                                <div className="guaranteed-modal">
                                    <div className="modal__pre-title"><p>{get(pageData,'preTitle','')}</p></div>
                                    <div className="modal__title"><h2>{get(pageData,'title','')}</h2></div>
                                    <div className="modal__main-text"><p>{get(pageData,'mainText','')}</p></div>
                                    <div className="modal__description"><p>{get(pageData,'description','')}</p></div>
                                    <div className="modal__informations"><p>{get(pageData,'informations','')}</p></div>
                                </div>
                            </Modal>
                            
                            {(enableChat || process.env.CHAT_ENABLED === "true") && <Chat />}
                        </>
                    )
                }}
            />
            {/* <CookieConsent
                location="bottom"
                buttonText="Got it!"
                cookieName="gdprCookie"
                style={{ background: "#198908" }}
                buttonStyle={{ color: "#fff", background: "#198908", borderWidth: "1px", borderStyle: "solid", borderColor: "#fff", fontSize: "16px", padding: "10px 20px" }}
                expires={150}
            >
                This website uses cookies to ensure you get the best experience on our website. Please refer to our privacy policy for more details: <Link to="/privacy-policy">Privacy Policy</Link>
            </CookieConsent> */}
        </>
    )
};

Layout.propTypes = {
    children: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.string,
    image: PropTypes.any,
    pathName: PropTypes.string,
    product: PropTypes.bool
};

export default Layout