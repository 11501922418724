import { get, first, mergeWith } from 'lodash'

export const getDataForLanguage = function getData(data, lang) {
    let langData = data.filter(item => get(item, "node_locale", "en-US") === lang);

    langData.map(item => {
        for (let key in item) {
            if (
                ["images", "image", "backgroundImage"].indexOf(key) > -1 &&
                item[key]
            ) {
                if (Array.isArray(item[key])) {
                    item[key].map(img => {
                        if (!img.file) {
                            const defaultValueItem = first(
                                data.filter(
                                    node =>
                                        node.contentful_id === item.contentful_id &&
                                        node.node_locale === "en-US"
                                )
                            )

                            defaultValueItem[key].map(defaultImg => {
                                if (img.contentful_id === defaultImg.contentful_id) {
                                    img.file = defaultImg.file
                                }
                            })
                        }

                        return img
                    })
                } else {
                    if (!get(item[key], "file.url", null)) {
                        const defaultVal = first(
                            data.filter(
                                node =>
                                    node.contentful_id === item.contentful_id &&
                                    node.node_locale === "en-US"
                            )
                        )
                        item[key]["file"] = {}
                        item[key]["file"]["url"] = get(defaultVal[key], "file.url", null)
                    }
                }
            }

            if (item[key] === null) {
                const defaultValueItem = data.filter(
                    node => node.contentful_id === item.contentful_id
                )
                item[key] = get(first(defaultValueItem), key, "")
            }
        }

        return item
    })

    return langData
}

export const fixCompareTwoArraysForImages = function fixCompareArrays(
    arr1,
    arr2
) {
    for (let i = 0; i < arr1.length; i++) {
        arr1[i] = mergeWith({}, arr2[i], arr1[i], (a, b) =>
            b === null ? a : undefined
        )
    }

    return arr1
}

export const getComplexData = function getComplexDataForKey(data, lang, key) {
    let defaultLangData = data
        .filter(item => item.node_locale === "en-US")
        .map(item => item[key])
    let translationLangData = data
        .filter(item => item.node_locale === lang)
        .map(item => item[key])

    return mergeWith({}, defaultLangData, translationLangData, (a, b) =>
        b === null ? a : undefined
    )
}