import React, { Component } from 'react'
//import icon from "../assets/images/chat_now.png";
import { Helmet } from 'react-helmet';

 
class chat extends Component {
    componentDidMount() {
        // setTimeout(()=>{
        //     initChat();
        // },2000);
        
    }
    render() {
        return (
            <Helmet>
                
                
                
                <script>
                    {`


                    window.astuteBotMessengerSettings = {
                        channelId: "9E5qJzhpMXwZRUZ_ldV-kA",
                        context: {
                            astuteBotApp: {
                                createContext: {
                                    userInfo: {
                                        //name: 'John Doe',
                                        //firstName: 'John',
                                        //lastName: 'Doe',
                                        //language: 'en'
                                    },
                                    brand: "OKeeffes"
                                }
                            }
                        }
                    };
                    function includeScript(e){var t=document.createElement("script"),n=document.getElementsByTagName("script")[0];t.type="text/javascript",t.src=e,t.async=!0,n.parentNode.insertBefore(t,n)}includeScript("https://www.astutebot.com/components/Launchers/Messenger/LauncherSelector.js");
                    `}
                
                </script>
            </Helmet>
        )
    }
}

export default chat



