import React from 'react'
import { StaticQuery, graphql, Link } from "gatsby"
import PropTypes from 'prop-types'
import ftrLogo from '../assets/images/ftr-logo.svg'
import iluminoIcon from '../assets/images/ilumino_accessibility_icon-white.png'
import SocialLinks from './socialLinks'
import {get,first} from 'lodash'
import { getDataForLanguage } from '../utils/getDataForLanguage'
import MenuItem from './helpers/menuItem'
var slugify = require('slugify')

function Footer(props) {
    const { openModal } = props;

    return (
        <StaticQuery
            query={graphql`
                query FooterInformations {
                    allContentfulSiteInformations {
                        edges {
                            node {
                                moneyBackTitle
                                moneyBackDescription
                                newsletterDescription
                                newsletterTitle
                                footerCopyright
                                node_locale
                                contentful_id
                                node_locale
                            }
                        }
                    }
                    allContentfulNavigation(filter: {title: {eq: "Footer"}}) {
                        edges {
                            node {
                                title
                                node_locale
                                contentful_id
                                navigationItems {
                                    title
                                    slug
                                    tabNumber
                                    node_locale
                                    contentful_id
                                    subNavigationItem {
                                        title
                                        slug
                                        tabNumber
                                        node_locale
                                        contentful_id
                                        subNavigationItem {
                                            title
                                            slug
                                            tabNumber
                                            node_locale
                                            contentful_id
                                        }
                                    }
                                }
                            }
                        }
                    }
                }`
            }
            render={(data) => {

                const allPageData = get(data, "allContentfulSiteInformations.edges", '');
                let pageData = first(getDataForLanguage(allPageData.map(item => item.node), process.env.COUNTRY));

                const allPageNavData = get(data, "allContentfulNavigation.edges", '');
                let pageNavData = first(getDataForLanguage(allPageNavData.map(item => item.node), process.env.COUNTRY));

                return (
                    <>
                        <footer className="footer">
                            <div className="footer__banner">
                            <button onClick={() => openModal()} >
                                <span className="title">
                                    {/* <div>Opens a page overlay</div> */}
                                    {get(pageData, 'moneyBackTitle','')}
                                </span>
                                <span className="desc">{get(pageData, 'moneyBackDescription','')}</span>
                            </button>
                            </div>
                            <div className="footer__inner">
                                <div className="container">
                                    <div className="logo-wrapper">
                                        <Link to="/">
                                            <img src={ftrLogo} alt="O'Keeffe's Company" />
                                        </Link>
                                    </div>
                                    <div className="footer-navigation">
                                        <div className="col-left">
                                            {pageNavData.navigationItems.map(({ title, subNavigationItem }) => {
                                                return(
                                                    <div key={slugify(title, { lower: true })} className={"footer-navigation__block footer-navigation__block--" + slugify(title, { lower: true })}>
                                                        <strong className="block-title block-title--product"
                                                            onClick={() => {
                                                                document.querySelector('.footer-navigation__block--' + slugify(title, { lower: true })).classList.toggle("is--open");
                                                            }}
                                                            onKeyPress={(e) => {
                                                                if(e.key === 'Enter') {
                                                                    // alert("key pressed enter");
                                                                    document.querySelector('.footer-navigation__block--' + slugify(title, { lower: true })).classList.toggle("is--open");
                                                                }
                                                            }}
                                                            tabIndex="0"
                                                        >{title}</strong>
                                                        {subNavigationItem &&
                                                            <ul className="footer-navigation__list">
                                                                {subNavigationItem.map((item)=> {
                                                                    return(<li key={slugify(item.title, { lower: true })}><MenuItem subNavigationItem={item} /></li>)
                                                                })}
                                                            </ul>
                                                        }
                                                    </div>
                                                )
                                            })}
                                            <div className="footer-navigation__block ">
                                            <button id="ot-sdk-btn" className="ot-sdk-show-settings custom">Do Not Sell My Personal Information</button>
                                                </div>
                                        </div>
                                        <div className="col-right">
                                            <div className="logo-wrapper">
                                                <Link to="/">
                                                    <img src={ftrLogo} alt="O'Keeffe's Company" />
                                                </Link>
                                            </div>
                                            <SocialLinks />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="footer__bottom">
                                <div className="container footer__bottom__wrapper">
                                    <Link to="/accessibility"><img src={iluminoIcon} alt="Accessibility Policy" /></Link>
                                    <div className="copyrights-text">
                                        {get(pageData, 'footerCopyright','')}
                                    </div>
                                    <SocialLinks />
                                </div>
                            </div>
                        </footer>
                    </>
                )
            }}
        />
    )
}

Footer.propTypes = {
    openModal: PropTypes.any
}

export default Footer