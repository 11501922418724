/* eslint-disable */
import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby';

const SubNavs = ({ title, value, tabIndex }) => {
    return (
        <>
            <div key={title} className={"product-dropdown__box product-dropdown__box--" + title}>
                <div className="product-dropdown__title product-dropdown__title--for-mobile" onClick={() => {
                    document.querySelector(".product-dropdown__box--" + title).classList.toggle('product-dropdown__box--active')
                    document.querySelector('.product-dropdown__list--' + title).classList.toggle('product-dropdown__list--active')
                }}>{title}</div>
                <Link to="/products" state={{ activeTab: tabIndex }} className="product-dropdown__title product-dropdown__title--for-desktop">{title}</Link>
                <ul className={"product-dropdown__list product-dropdown__list--" + title}>
                    {value.map((nav) => {
                        return (
                            <li key={nav.slug}><Link to={"/products/" + nav.slug}>{nav.title}</Link></li>
                        )
                    })}

                </ul>
            </div>
        </>
    );
}

SubNavs.propTypes = {
    value: PropTypes.array,
    title: PropTypes.string,
    tabIndex: PropTypes.number
}

export default SubNavs